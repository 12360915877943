<template>
	<RightDrawer :visible.sync="visible">
		<template slot="content">
			<ApprovalHeader :details="details"></ApprovalHeader>
			<p class="other_info" v-if='details.add_info.length>0'>附加申请</p>
			<template v-if='details.add_info.length>0'>
				<a-divider  />
				<div >
					<a-descriptions v-for='(appro_info,index) in details.add_info' :key='index' >
						<a-descriptions-item :span="3" v-for='(info,indx) in appro_info' :key='indx' :label="info.label">{{info.value}}</a-descriptions-item>
					</a-descriptions>
				</div>
			</template>
			<a-divider  />
			<a-descriptions v-if='details.trip_route'>
				<a-descriptions-item :span="3" label="出行人员">{{details.traveler_name}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="出差事由">{{details.reason}}</a-descriptions-item>
				<template>
					<a-descriptions-item :span="3" v-for="(info,index) in details.trip_route" :key='index'>
						<p class="item_p">行程{{index+1}}</p>
						<p class="item_p"><span class="item_lable">开始时间：</span><span>{{info.start_time}}</span></p>
						<p class="item_p"><span class="item_lable">结束时间：</span><span>{{info.end_time}}</span></p>
						<p class="item_p"><span class="item_lable">出发地：</span><span>{{info.start_place_name}}</span></p>
						<p class="item_p"><span class="item_lable">目的地：</span><span>{{info.end_place_name}}</span></p>
							<p class="item_p"><span class="item_lable">备注：</span><span>{{info.remark||'无' }}</span></p>
					</a-descriptions-item>
				</template>
				</a-descriptions>
				
				<a-descriptions v-else-if='details.live_detail'>
					<a-descriptions-item :span="3" label="代订事由">{{details.reason}}</a-descriptions-item>
				<template>
					<a-descriptions-item :span="3" v-for="(info,index) in details.live_detail" :key='index'>
						<p class="item_p">代订信息{{index+1}}</p>
						<p class="item_p"><span class="item_lable">入住地：</span><span>{{info.live_place_name}}</span></p>
						<p class="item_p"><span class="item_lable">开始时间：</span><span>{{info.start_time}}</span></p>
						<p class="item_p"><span class="item_lable">结束时间：</span><span>{{info.end_time}}</span></p>
						<p class="item_p"><span class="item_lable">住宿标准：</span><span>{{info.standard_name}}</span></p>
						<p class="item_p"><span class="item_lable">住宿人数：</span><span>{{info.user_num}}</span></p>
						<p class="item_p"><span class="item_lable">预定间数：</span><span>{{info.room_num}}</span>
						</p>
					</a-descriptions-item>
				</template>
			</a-descriptions>
			
			<a-divider />
			<div class="drawer_title">审批进度</div>
			<div>
				<p class="approve_time_long" v-if="approval.length>0"><span>审批耗时</span><span >{{details.approve_time_long}}</span></p>
				<a-timeline class="time_line">
					<a-timeline-item class="li"  
						   :class="[index == approval.length - 1 ? 'last_li' : '']"
					v-for="(item, index) in approval" :key='index'>
						<div class="title">
							<span v-if="item.type == 'director'">指定上级</span>
							<span v-else-if="item.type == 'appoint'">指定成员</span>
							<span v-else-if="item.type == 'optional'">发起人自选</span>
							<span v-else-if="item.type == 'role'">指定角色</span>
							<span v-else-if="item.type == 'user'">审批人
								<span>{{ item.user[0].username }}</span>
								<span> {{ item.user[0].approve_time }}</span>
							</span>
							<template v-for="manner in approval_manner_list">
								<span class="tit_info" v-if="manner.value == item.mode">({{ manner.label }})</span>
							</template>
						</div>
						<div v-if="item.type == 'user'">
							<div v-if="item.user[0].sign">
								<LookImages :list="[item.user[0].sign]"></LookImages>
							</div>
						</div>
						<div v-else class="avatar_ul">
							<div v-for="vo in item.user" class="avatar">
								<Avatar :show_name="true" :value="vo.username" :show_enter="vo.status > 0"></Avatar>
							</div>
						</div>
						<div v-if="item.type == 'user'" style="padding-top: 10px">
							{{ item.user[0].desc }}
						</div>
					</a-timeline-item>
				</a-timeline>
			</div>
			<!-- <ApprovalSpeed :list="details.approver"></ApprovalSpeed> -->
			<div class="drawer_title" v-if="details.cc_user.length">抄送</div>
			<ApprovalCc :list="details.cc_user"></ApprovalCc>
		</template>
	</RightDrawer>
</template>

<script>
	import RightDrawer from "@/components/RightDrawer";
	import {
		getApprovalDetail
	} from '@/api/evection.js'
	import ApprovalHeader from "@/components/ApprovalHeader";
	import ApprovalSpeed from "@/components/ApprovalSpeed";
	import ApprovalCc from "@/components/ApprovalCc";
	import Avatar from "@/components/small/Avatar";
	import LookImages from "@/components/LookImages";
	export default {
		props: {

		},
		components: {
			RightDrawer,
			ApprovalHeader,
			ApprovalSpeed,
			ApprovalCc,
			Avatar,
			LookImages
		},
		data() {
			return {
				approval: [],
				visible: false,
				details: {
                    add_info:[],
					cc_user: [],
					trip_route: [],
					book_plan:[],
					over_standard:{}
				},
				approval_manner_list: [{
						label: '依次审批',
						value: 'order'
					},
					{
						label: '会签审批',
						value: 'jointly'
					},
					{
						label: '或签审批',
						value: 'or'
					},
				],
			}
		},
		created() {

		},
		methods: {
			
			get_details(record) {
				getApprovalDetail({
					data: {
						id: record.id,
						type: record.approval_type_id 
					}
				}).then(res => {
					let data = res.data.list.info_data;
					this.details = data;
					this.visible = true;
					this.details.cc_user=  res.data.list.flow.cc_user
					let approval_user = [];
					let flow = res.data.list.flow.flow
					if (flow.approval_user) {
						approval_user = flow.approval_user.map((item) => {
							return {
								type: 'user',
								user: [item]
							}
						})
					}
					this.approval = [...flow.approval, ...approval_user]
				})
			},
		}
	}
</script>
<style lang="less" scoped>
.time_line {
    width: 520px;
    margin: 42px auto 0;
    .li {
        &.last_li {
            padding-bottom: 30px;
        }
        &.li_add {
            padding-bottom: 0px;
        }
        .item {
            position: relative;
            z-index: 1;

            &.item_add {
                display: flex;
                transform: translateY(-10px);
            }
            .tit {
                color: #333;
                display: flex;
                line-height: 24px;
            }
            .icon {
                cursor: pointer;
                padding: 0 5px;
                display: none;
                &.drag {
                    display: block;
                }
            }
        }
        &:hover {
            .item {
                .icon {
                    display: block;
                }
            }
        }
    }
}
	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.item_p {
		height: 40px;
		color: #000;
		line-height: 40px;

		.item_lable {
			// width: 100px;
			display: inline-block;
			margin-right: 5px;
		}

		span:nth-child(2) {
			color: #333;
		}
	}
	.room_title {
		height: 40px;
		line-height: 40px;
		width: 100%;
		display: flex;
		span{
			flex: 1;
			text-align: center;
		}
		background:rgba(245, 245, 245, 1);
	}
	.room_item {
		height: 40px;
		line-height: 40px;
		width: 100%;
		display: flex;
		span{
			flex: 1;
			text-align: center;
		}
		background: none;
		border:1px solid rgba(245, 245, 245, 1);
	}
	.approve_time_long {
		display: flex;
		margin-top: 10px;
		span:nth-child(1) {
			width: 80px;
		}
	}
	.other_info {
		margin-top: 20px;
	}
</style>
